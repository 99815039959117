import type { FormatParser, SizeFormat } from 'roosterjs-content-model-types';

/**
 * @internal Do not paste width for Format Containers since it may be generated by browser according to temp div width
 */
export const containerSizeFormatParser: FormatParser<SizeFormat> = (format, element) => {
    // For pasted content, there may be existing width generated by browser from the temp DIV. So we need to remove it.
    if (element.tagName == 'DIV' || element.tagName == 'P') {
        delete format.width;
        delete format.height;
    }
};
